import React from "react"

import Layout from "../app/layouts/default"
import {icons} from '../icons/_candyIcons/selection.json';
import Icon from "../app/atoms/icon";

const IndexPage = () => {

  return (
    <Layout>
        <div className="container icons-demo-container">
            { icons.map( (icon, idx) => (
                <div key={idx} className="icon-container">
                    <Icon color="blue" name={icon.properties.name} />
                    {icon.properties.name}
                </div>
            ) ) }
        </div>
    </Layout>
  )
};

export default IndexPage



